.unique-box.unique-voucher-box {
    background-color: #82A575;
    padding: 30px 0;
    color: #ffffff;
    text-align: center;
}

.unique-voucher-input {
    background-color: #f9f9f9;
    padding: 15px;
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    box-sizing: border-box;
    border-radius: 10px;
    border-style: solid;
    border-width: 0;
}
