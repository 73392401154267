body {
  margin: 0;
  font-family: "Avenir Next", sans-serif;
}

.unique-box {
  box-shadow: 0 4px 4px rgba(208, 208, 208, 0.1);

  border-radius: 10px;
  width: 100%;
  max-width: 1300px;
  margin-bottom: 20px;
}



.mapboxgl-canvas {
   
    border-radius: 10px;
}
