.unique-header {
    height: 50px;

    color: #222222;
    font-style: normal;
    text-transform: capitalize;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.unique-header .unique-title {
    font-family: "Avenir Next", sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.05em;
    margin: 0 0 0 0;
}
