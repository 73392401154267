.unique-spinner {
    width: 80px;
    height: 80px;
    margin: 100px auto;
    background-color: #333;

    border-radius: 100%;
    -webkit-animation: unique-scaleout 1.0s infinite ease-in-out;
    animation: unique-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes unique-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes unique-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
          opacity: 0;
      }
}
