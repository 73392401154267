.unique-info-box {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #eaeaea;
    text-align: center;
}

.unique-info-box .unique-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin: 0 0 30px 0;
}

.unique-info-box .unique-subline {
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 12px 0;
}

.unique-info-box .unique-coordinates {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 2px;
    margin: 0;
}
