.TagPage {
  min-height: 100vh;
  height: 100%;
}

.TagPage .unique-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
